import * as React from 'react';
import CheckboxLabels from '../components/customComponent/Checkbox';
import { Typography, Button } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { appTheme } from '../theme/colorTheme';
import { getGoolePayPaymentUrl, getImgSrc, getPaytmPaymentUrl, getPonepePaymentUrl, getUpiPaymentUrl } from '../constants/Helper';
import phonePe from '../img/phonepe.svg';
import gPay from '../img/gpay.svg';
import upi from '../img/upi.svg';
import paytm from '../img/paytm.svg'

const PaymentModeScreen = (props) => {
  const [selectedPaymentType, setSelectedPaymentType] = React.useState([])


  return (
    <React.Fragment>

      <div style={{ display: 'flex', color: appTheme.color1, justifyContent: 'space-between', fontSize: 11, borderBottom: '1px solid white', padding: '10px 0' }}>
        <div style={{ display: 'flex', color: appTheme.color1 }}>
          <div style={{ marginRight: '10px' }}>Mode</div>
          <div>Display Name</div>
        </div>
        <div>Upi Details</div>
      </div>

      {props.data.map((row) => (
        <div style={{ borderBottom: '1px solid white', padding: '5px 0' }}>
          <div key={row.id} style={{
            display: 'flex', color: appTheme.color1, justifyContent: 'space-between', alignItems: 'center', fontSize: 11, padding: '5px 0'
          }}>
            <div style={{ display: 'flex', color: appTheme.color1, alignItems: 'center' }}>
              <div style={{ marginRight: '10px' }}>
                <CheckboxLabels checked={selectedPaymentType?.id === row?.id} onChange={(e, newValue) => {
                  if (newValue) {
                    setSelectedPaymentType(row);
                    props.handleChecked(row, newValue);
                  } else {
                    setSelectedPaymentType(null)
                    props.handleChecked(null, newValue);
                  }
                }} />

              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={!!getImgSrc(row?.logo_url) ? getImgSrc(row.logo_url) : require('../img/no-pictures.png')}
                  style={{ verticalAlign: 'middle', borderRadius: 50, width: "30px", marginRight: 8 }}

                  alt="logo"
                  loading="lazy"
                /><Typography variant="caption" gutterBottom fontSize={11} style={{ width: "100px", wordWrap: "break-word", overflowWrap: "anywhere" }}>{row.account_display_name}</Typography>
              </div>
            </div>
            <div style={{ width: "180px", wordWrap: "break-word", textAlign: "right", overflowWrap: "anywhere" }}>
              <Typography variant="caption" display="block" gutterBottom fontSize={11}>
                <CopyToClipboard text={row.account_identifier}>
                  <span style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 11 }} onClick={props.handleClick}>
                    {row.account_identifier}&nbsp;
                    <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
                </CopyToClipboard>
              </Typography>
            </div>
          </div>
          {
            selectedPaymentType?.id === row?.id &&
            <>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" fontSize={14} display="block" gutterBottom color={appTheme.color1}>
                Pay via:
              </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button sx={{
                  cursor: 'pointer', background: appTheme.color1, padding: '5px', border: `1px solid ${appTheme.color12}`, borderRadius: '5px', marginRight: '5px',
                  "&:hover": {
                    bgcolor: appTheme.color1
                  }
                }} onClick={() => {
                  window.location.href = getPonepePaymentUrl(row.account_identifier, row.account_display_name, props.amount);
                }}>
                  <img src={phonePe} alt='phonePeIcon' title='PhonePe' />
                </Button>
                <Button sx={{
                  cursor: 'pointer', background: appTheme.color1, padding: '5px', border: `1px solid ${appTheme.color12}`, borderRadius: '5px', marginRight: '5px',
                  "&:hover": {
                    bgcolor: appTheme.color1
                  }
                }}
                  onClick={() => {
                    window.location.href = getGoolePayPaymentUrl(row.account_identifier, row.account_display_name, props.amount);
                  }}>
                  <img src={gPay} alt='googlPayIcon' title='Google Pay' />
                </Button>
                <Button sx={{
                  cursor: 'pointer', background: appTheme.color1, padding: '5px', border: `1px solid ${appTheme.color12}`, borderRadius: '5px', marginRight: '5px',
                  "&:hover": {
                    bgcolor: appTheme.color1
                  }
                }}
                  onClick={() => {
                    window.location.href = getPaytmPaymentUrl(row.account_identifier, row.account_display_name, props.amount);
                  }}>
                  <img src={paytm} alt='paytmIcon' title='Paytm' />
                </Button>
                <Button sx={{
                  cursor: 'pointer', background: appTheme.color1, padding: '5px', border: `1px solid ${appTheme.color12}`, borderRadius: '5px', marginRight: '5px',
                  "&:hover": {
                    bgcolor: appTheme.color1
                  }
                }}
                  onClick={() => {
                    window.location.href = getUpiPaymentUrl(row.account_identifier, row.account_display_name, props.amount);
                  }}>
                  <img src={upi} alt='upiIcon' title='UPI' />
                </Button>
              </div>
            </>
          }
        </div>
      ))}
    </React.Fragment>

  )
}

const PaymentMode = React.memo(PaymentModeScreen)

export default PaymentMode;
